@tailwind base;
@tailwind utilities;
@tailwind components;

html,
body,
#root {
  font-family: "Madimi One", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  caret-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  box-sizing: border-box;
  font-weight: 400;
  height: 100%;
  width: 100%;
}

::-moz-selection {
  color: white;
  background: #a5a39f49;
}

::selection {
  color: white;
  background: #a5a39f49;
}

@layer components {
  .sidebar-item {
    @apply flex items-center p-2 rounded-lg hover:bg-black hover:shadow-lg hover:border-transparent flex-shrink mx-2 my-1 transition-all ease-linear duration-300;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.scrollbar-thin::-webkit-scrollbar {
  width: 0.5em;
  border-radius: 100px;
  background-color: #e4e4e4;
}

.scrollbar-black-thumb::-webkit-scrollbar-thumb {
  background-color: rgb(0, 0, 0);
  border-radius: 100px;
}

.scrollbar-white-thump::-webkit-scrollbar-thumb {
  background-color: rgb(255, 255, 255);
  border-radius: 100px;
}

.mymodal {
  max-height: calc(100vh - 100px);
  background: #fff;
  border-radius: 5px;
  overflow: auto;
  position: fixed;
  outline: none;
}

.myoverlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.55);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
